/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import ImgSucesso from "@/images/lp/home/sucesso.svg"

import { 
  contentPage,
}  from '../assets/styles/Other.styles'

const NotFoundContent = () => {
  return (
    <Fragment>
      <section css={contentPage}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="content-mensage">
                <h2>Obrigado pelo seu interesse</h2>
                <p>Nosso time comercial entrará em contato com você</p>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img src={ImgSucesso} alt='/' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default NotFoundContent;
